import React from 'react';
import './Contact.css';

export default function Contact() {
    return (
        <div className="contactContainer">        
            <div className="contactBox">
                <div className="contact">
                    <p className="contactP">
                        <img className="imgContact" src={require('../img/gmail.png')} width="40px" alt="email"></img>
                        nahu.mendez.31@gmail.com
                    </p>
                    <p className="contactP">
                        <img className="imgContact" src={require('../img/phone.png')} width="40px" alt="phone"></img>
                        <a>+(54) 9 11 3287 5336</a>
                    </p>
                </div>
                <div className="contact">
                    <p className="contactP">
                        <img className="imgContact" src={require('../img/githubLogo.png')} width="40px" alt="email"></img>
                        <a href="https://www.github.com/Nmx11">My Github</a>
                    </p>
                    <p className="contactP">
                        <img className="imgContact"src={require('../img/linkedinLogo.png')} width="40px" alt="email"></img>
                        <a href="https://www.linkedin.com/in/alejandro-nahuel-mendez">My Linkdedin</a>
                    </p>
                </div>           
                <p className="contactP">I'm available for work at this moment!</p>     
            </div>
        </div>
    );  
}