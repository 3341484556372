import React from 'react';
import './App.css';
import {Route} from 'react-router-dom';
import Home from './components/Home.jsx';
import About from './components/About.jsx';
import Contact from './components/Contact.jsx';
import Navbar from './components/Nav.jsx';

function App() {
  return (
    <div className="App">
        <Navbar></Navbar>
      <div className="homeDiv">
        <Route path='/' exact component={Home}/>
        <Route path='/about' exact component={About}/>
        <Route path='/contact' exact component={Contact}/>
      </div> 
      <div className="bot">
            <footer className="foot"><p>Copyright ©</p><p>Credits to Flaticon and all logo owners</p></footer>
      </div> 
    </div>
  );
}

export default App;
