import React from 'react';
import Nav from './Nav.jsx';
import Logos from './Logos.jsx';
import './Home.css';

// import Card from './Card.jsx';

import styles from './Home.css';

export default function Header() {
  // acá va tu código
  // return <div>Card Component</div>
  // return <div>{props.name}</div>

  return (
    <div className="headBox">        
        <div className="header">
            <div className="titulo">
                <h1>Alejandro Nahuel Mendez</h1>
                <h3>Web Developer</h3>
            </div>
            <div>
                <img className="perfil" src={require('../img/perfilOpenToWork.jpg')} width="80" height="80" alt="foto de perfil"></img>
            </div>
        </div>
        <div className="logosBody">
            <Logos></Logos>
        </div>
    </div>
  );
};
