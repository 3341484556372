import React from 'react';
import Nav from './Nav.jsx';
import './Logos.css';
import './Logos.scss';
import './LogosRotate.scss';

// import Card from './Card.jsx';

import styles from './Home.css';

export default function Logos() {

  return (
        <div className="logos">
          {/* <div id="uno">
            <img className="logoPhone" src={require('../img/express.png')} width="80" height="80" alt="Express logo"></img>
            <img className="logoPhone" src={require('../img/sequelize.png')} width="80" height="80" alt="Sequelize logo"></img>
          </div>
          <div id="dos">
            <img className="logoPhone" src={require('../img/webApi.jpg')} width="80" height="80" alt="Web Api logo"></img>
            <img className="logoPhone" src={require('../img/postgresql.png')} width="80" height="80" alt="PostgreSQL logo"></img>
          </div> */}
          <div className="logos1">
              <img className="logoPhone" src={require('../img/webApi.jpg')} width="80" height="80" alt="JQuery logo"></img>
              <img className="logoPhone" src={require('../img/ajax.png')} width="80" height="80" alt="GitHub logo"></img>
            </div>
      
            <div className="logos2">
              <img className="logoPhone" src={require('../img/insomnia.png')} width="80" height="80" alt="Insomnia logo"></img>
              <img className="logoPhone" src={require('../img/express.png')} width="80" height="80" alt="Webpack logo"></img>
            </div>

            <div className="logos1b">
              <img className="logoPhone" src={require('../img/redux.png')} width="80" height="80" alt="JQuery logo"></img>
              <img className="logoPhone" src={require('../img/html5.png')} width="80" height="80" alt="GitHub logo"></img>
            </div>
      
            <div className="logos2b">
              <img className="logoPhone" src={require('../img/css.png')} width="80" height="80" alt="Insomnia logo"></img>
              <img className="logoPhone" src={require('../img/git3.png')} width="80" height="80" alt="Webpack logo"></img>
            </div>
         
          <div>
            <ul className="circle-container">        
                  <li classname ="logo"><img className="logo1" src={require('../img/redux.png')} width="80" height="80" alt="Redux logo"></img><p>REDUX</p></li>
                  <li> <img className="logo" src={require('../img/html5.png')} width="80" height="80" alt="Html 5 logo"></img><p>HTML5</p></li>
                  <li><img className="logo" src={require('../img/css.png')} width="80" height="80" alt="CSS 3 logo"></img><p>CSS</p></li>
                  <li> <img className="logo" src={require('../img/git3.png')} width="80" height="80" alt="GIT logo"></img><p>GIT</p></li>
                  <li><img className="logo" src={require('../img/bootstrap.png')} width="80" height="80" alt="BOOTSTRAP logo"></img><p>BOOTSTRAP</p></li>
                  <li> <img className="logo" src={require('../img/nodeJs1.png')} width="80" height="80" alt="Node JS logo"></img><p>NODE JS</p></li>
                  <li><img className="logo" src={require('../img/javascript.png')} width="80" height="80" alt="Javascript logo"></img><p className='javascript'>JAVASCRIPT</p></li>
                  <li> <img className="logo" src={require('../img/react.png')} width="80" height="80" alt="React logo"></img><p>REACT</p></li>
              </ul>
          </div>
            <div className="logos3b">
              <img className="logoPhone" src={require('../img/bootstrap.png')} width="80" height="80" alt="JQuery logo"></img>
              <img className="logoPhone" src={require('../img/nodeJs1.png')} width="80" height="80" alt="GitHub logo"></img>
            </div>
      
            <div className="logos4b">
              <img className="logoPhone" src={require('../img/javascript.png')} width="80" height="80" alt="Insomnia logo"></img>
              <img className="logoPhone" src={require('../img/react.png')} width="80" height="80" alt="Webpack logo"></img>
            </div>

            <div className="logos3">
              <img className="logoPhone" src={require('../img/sequelize.png')} width="80" height="80" alt="JQuery logo"></img>
              <img className="logoPhone" src={require('../img/postgresql.png')} width="80" height="80" alt="GitHub logo"></img>
            </div>
      
            <div className="logos4">
              <img className="logoPhone" src={require('../img/github.jpg')} width="80" height="80" alt="Insomnia logo"></img>
              <img className="logoPhone" src={require('../img/webpack.jpg')} width="80" height="80" alt="Webpack logo"></img>
            </div>

        </div>
  );
};