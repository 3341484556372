import React from 'react';
import './About.css';

export default function About() {
    return (
        <div className="about">        
       
        <div className="box">
            <p>Hi!, thanks for visiting my site!.</p>
            <p>I'm a jr. Full-stack web developer with knowledge of javascript, Node.js, Express, React, Redux and other technologies.</p>        
            <p>I'm currently finishing the web development Bootcamp at @SoyHenry, in which i´m being trained in the most current and used technologies in the world of web development right now, as well as in using the "Scrum" teamwork methodology for developing collectively. I studied visual arts at art school for a little over 5 years, where I learned to use creativity to solve problems and also studied Hotel Management, which taught me to work in an organized manner, with humility and willingness to provide a pleasant and high quality service.</p>
            <p>I'm excited to enter the world of web development, so I can use my knowledge, contribute with my ideas and collaborate with colleagues to create quality content that can be of use for many people and/or entities.</p>
            <p>Technologies i know:</p>
            <p>Git | HTML | CSS | Javascript | ES6 | ES5 | Data structures | Algorithms | Bootstrap | React | Redux | Ajax | Node JS | Express | WebAPI's | SQL | Sequelize | Testing (Mocha, Jasmine, Chai) | Front-End | Back-End </p>
        
        
        </div>
        </div>
    );  
}